import React from 'react';
import { graphql } from "gatsby";

// import pic1 from '../../../images/nature-in-transition/pic01.jpg';
import Layout from '../../../components/common/layout';
import Footer from '../../../components/common/Footer';

import Artists from '../../../components/artists/Artists';
// import Header from '../components/common/Header';


const ArtistsPage = ({location, data, langKey}) => (
  <Layout location={location} langKey={langKey}>

    <div id="wrapper">
      <section id="main" className="wrapper">
        <div className="inner">
          <h1 className="major">Listamenn</h1>
          <span className="image fit">
            {/* <img src={pic1} alt="" /> */}
					</span>
					<Artists artists={data.natureintransitionJson.artists} langKey={langKey} />
          <p>
            Donec eget ex magna. Interdum et malesuada fames ac ante ipsum
            primis in faucibus. Pellentesque venenatis dolor imperdiet dolor
            mattis sagittis. Praesent rutrum sem diam, vitae egestas enim auctor
            sit amet. Pellentesque leo mauris, consectetur id ipsum sit amet,
            fergiat. Pellentesque in mi eu massa lacinia malesuada et a elit.
            Donec urna ex, lacinia in purus ac, pretium pulvinar mauris.
            Curabitur sapien risus, commodo eget turpis at, elementum convallis
            elit. Pellentesque enim turpis, hendrerit tristique.
          </p>
        </div>
      </section>
    </div>

    <Footer />
  </Layout>
);

export default ArtistsPage;


export const ArtistsIsQuery = graphql`
  query ArtistsIsQuery {
		natureintransitionJson {
			artists {
				name
				country
				to {
					title
					href
					label
				}
			}
		}
  }
`;
